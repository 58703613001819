import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { toSnakeCase } from 'utils/index';
import axios from 'utils/axios';
import AttachFile from '@material-ui/icons/AttachFile';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Grid, Image, Message, Select } from 'semantic-ui-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './md-style';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Card from '../Card';
import './style.scss';

const HidePhotos = () => {
  const [disabled, setDisabled] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [photos, setPhotos] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [success, setSuccess] = useState(false);

  const getPropertyPhotos = (propertyCodes, photoTags) => {
    const params = new URLSearchParams();
    propertyCodes.forEach((code) => {
      params.append('property_code', code);
    });
    if (!photoTags.length > 0 || photoTags.includes('All')) {
      photoTags = tags.map((tag) => tag.value);
      setSelectedTags(photoTags);
    }
    photoTags.forEach((tag) => {
      if (tag !== 'All') {
        params.append('photo_tag', tag);
      }
    });
    return axios.get('/api/admin/properties/pictures/by-tag', {
      params: {
        property_code: params.toString(),
        photo_tag: params.toString(),
      },
    });
  };

  const getTagMap = async () => {
    const response = await axios.get('/api/admin/photo-tags');
    const tagsArray = response.data;

    const parsedTags = tagsArray.map((tag) => {
      return { text: tag.name, value: tag.id };
    });
    parsedTags.push({ text: 'All', value: 'All' });

    const sortedTags = parsedTags.sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;

      return 0;
    });

    setTags(sortedTags);
  };

  const handleHidePhotos = async () => {
    const params = new URLSearchParams();

    photos.forEach((photo) => {
      params.append('photo_id', photo.id);
    });

    try {
      await axios.delete('/api/admin/properties/pictures/hide', {
        params: {
          photo_id: params.toString(),
        },
      });
      setMessage({
        messageType: 'positive',
        description: `Photos hidden: ${photos.length}`,
      });
      setSelectedFile(undefined);
      setPhotos([]);
      setColumnData([]);
    } catch (error) {
      setHasError(true);
      setMessage({
        messageType: 'negative',
        description: 'Photos were unable to be hidden, make sure your property ids are correct and try again',
      });
    }
  };

  const handleTagChange = (_, { value }) => {
    setSelectedTags(value);
  };

  const handleFileUpload = () => {
    setDisabled(true);
    setHasError(false);
    setMessage(undefined);

    Papa.parse(selectedFile, {
      complete: async (result) => {
        try {
          if (result.data.length === 0) {
            setHasError(true);
            setMessage({ messageType: 'negative', description: 'File is empty' });
          } else if (!('property_code' in result.data[0])) {
            setHasError(true);
            setMessage({
              messageType: 'negative',
              description:
                'The file is not in the correct format. The header must be named "property code", "property_code" or "Property Code"',
            });
          } else {
            const propertyCodes = result.data.map((column) => column['property_code']);
            const resp = await getPropertyPhotos(propertyCodes, selectedTags);
            setMessage({ messageType: 'positive', description: resp.statusText });
            setPhotos(resp.data);
            setColumnData(propertyCodes);
          }
        } catch (error) {
          setHasError(true);
          setMessage({ messageType: 'negative', description: 'There was an error uploading the file' });
        } finally {
          setDisabled(false);
        }
      },
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setHasError(false);
    setMessage(undefined);
    setColumnData([]);
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled,
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: { 'text/csv': [] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(hasError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, hasError],
  );

  const renderPropertyPhotos = () => {
    return (
      photos.length > 0 && (
        <div className="file-details__container">
          <br />
          <h4>Property Photos:</h4>
          <ul>
            <Grid columns={3} padded>
              <Grid.Row>
                {photos.slice(0, 20).map((photo) => (
                  <Grid.Column key={photo.id}>
                    <Image src={photo?.image_url} />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </ul>
        </div>
      )
    );
  };

  const closeModal = () => {
    setIsActive(false);
  };

  const confirmToggle = () => {
    handleHidePhotos();
    closeModal();
  };

  const openHideModal = () => {
    setSuccess(false);
    setIsActive(true);
  };

  const renderHideModal = () => (
    <Box style={{ padding: '30px 0 0 32px' }} p={{ xs: 2, sm: 3, md: 4 }}>
      {success && (
        <Message positive>
          <Message.Header>Total photos hidden: {photos.length}</Message.Header>
          <p>Your changes have been saved successfully.</p>
        </Message>
      )}
      <div>
        <Modal isOpen={isActive}>
          <ModalBody>
            <p>Are you sure that you want to hide these photos?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={confirmToggle}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        <div className="file-upload__hide">
          <Button variant="contained" color="danger" disabled={disabled} onClick={openHideModal}>
            Hide Photos
          </Button>
        </div>
      </div>
    </Box>
  );

  useEffect(() => {
    getTagMap();
  }, []);

  const messageTitle = message?.messageType === 'positive' ? 'Success' : 'Fail';

  return (
    <Card>
      {message ? (
        <Message
          positive={message.messageType === 'positive'}
          negative={message.messageType === 'negative'}
          onDismiss={() => {
            setMessage(undefined);
          }}
        >
          <Message.Header>{messageTitle}</Message.Header>
          <p>{message.description}</p>
        </Message>
      ) : null}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CloudUpload color="primary" fontSize="large" />
        <span className="drag-drop__text">Drag and drop your CSV file here to start uploading</span>
        <span className="drag-drop__text">or</span>
        <Button
          variant="contained"
          color="primary"
          classes={{ containedPrimary: 'drag-drop__button' }}
          disabled={disabled}
        >
          Browse a file
        </Button>
      </div>

      {selectedFile ? (
        <div className="file-upload">
          {disabled ? <LinearProgress /> : null}

          <div className="file-upload__text">
            <AttachFile color="primary" />
            <span>{selectedFile.name}</span>
          </div>
          <div className="file-upload__select">
            <Select
              style={{ marginTop: '12px' }}
              multiple
              name="tags"
              options={tags}
              onChange={handleTagChange}
              placeholder="Photo Tags"
            />
          </div>
          <div className="file-upload__button">
            <Button
              variant="contained"
              color="primary"
              classes={{ containedPrimary: 'file-upload__button' }}
              disabled={disabled}
              onClick={handleFileUpload}
            >
              Get Photos
            </Button>
          </div>
        </div>
      ) : null}

      {columnData.length > 0 ? (
        <div className="file-properties">
          <h5>Total: {columnData.length} properties</h5>
        </div>
      ) : null}

      {renderPropertyPhotos()}

      {columnData.length > 0 ? (
        <Grid container columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div className="file-photos">
                <h5>Total: {photos.length} photos</h5>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="file-upload__hide">{renderHideModal()}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
    </Card>
  );
};

export default HidePhotos;
